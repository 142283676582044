@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

html,
body,
#root,
.app{
  height: 100%;
  width: 100%;
  position: relative; 
  min-height: 200px; 
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
}
.content {
  height: 100%;
  width: 100%;
  position: relative; 
  min-height: 200px; 
  padding-left: 80px;
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
}
.content-open {
  height: 100%;
  width: 100%;
  position: relative; 
  min-height: 270px; 
  padding-left: 275px;
  font-family: "Poppins", sans-serif;
  transition: all 0.5s;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.input-custom{
  width: 50%;
}

.image-login{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 315px;
  object-fit: contain;
}
.title-login{
  color: #1D2053;
  text-align: center;
}
.hidden{
  display: block;
}

@media screen and (max-width: 900px) {
  .hidden{
    display: none !important;
  }
  .input-custom{
    width: 60%;
  }
}